import { FullWidthField } from '@rategravity/marketing-components';
import { OwnUpBox } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import HouseOutsideTreesSVG from '../../images/house-outside-trees.svg';
import { colors } from '../../modules/colors';
import { CallToAction, CallToActionContent, Content, GreenWithHouseProps } from './components';

export const GreenWithHouse = ({ page }: GreenWithHouseProps) => (
  <FullWidthField backgroundColor={colors.MINT} desktopMaxWidth={1280} mobileLateralGutter={0}>
    <Content role="complementary">
      <OwnUpBox
        style={{
          margin: 'auto',
          maxWidth: 210
        }}
      >
        <img alt="" src={HouseOutsideTreesSVG} />
      </OwnUpBox>
      <CallToAction>
        <CallToActionContent page={page} />
      </CallToAction>
    </Content>
  </FullWidthField>
);
